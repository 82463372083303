/*           Labels & Progress-bar              */
.label{
    padding: 0.2em 0.6em;
    border-radius: 10px;
    color: #FFFFFF;
    font-weight: 500;
    font-size: 0.75em;
    text-transform: uppercase;
    display: inline-block;
    margin-bottom: 3px;
    line-height: 17px;
}
.label-primary{
    background-color: $primary-color;
}
.label-info{
    background-color: $info-color;
}
.label-success{
    background-color: $success-color;
}
.label-warning{
    background-color: $warning-color;
}
.label-danger{
    background-color: $danger-color;
}
.label-default{
    background-color: $default-color;
}
.error-message {
    font-size: 11px;
    color:red;
    font-weight: 600;
    opacity: 1;
        animation-name: fadeInOpacity;
        animation-iteration-count: 1;
        animation-timing-function: ease-in;
        animation-duration: 2s;
        
    }
    @keyframes fadeInOpacity {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

.success-message {
        font-size: 15px;
        color:#000;
        border: 1px solid rgba(45, 141, 45, 0.658);
        width:500px;
        text-align: center;
        border-radius: 20px;
        padding:10px;
        margin: 10px;
        background-color: rgba(83, 255, 83, 0.658);
        font-weight: 500;
        opacity: 1;
            animation-name: fadeInOpacity;
            animation-iteration-count: 1;
            animation-timing-function: ease-in;
            animation-duration: 2s;
            
        }
        @keyframes fadeInOpacity {
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        }
